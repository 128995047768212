import { Table, Typography } from '@bbnpm/bb-ui-framework';

import { StatusIcon } from '../CommonComponents';
import { PageWrapper } from '../CommonComponents';

const RegistrationsListPage = ({
  registrations,
  institution,
  tradingChallenge,
}) => {
  return (
    <PageWrapper width="1140px">
      <Typography.PageTitle>
        {institution.name} Registrations for {tradingChallenge.name}
      </Typography.PageTitle>

      <Table>
        <thead>
          <th>Team ID</th>
          <th>Team Name</th>
          <th>Captain Name</th>
          <th>Captain UUID</th>
          <th>Professor Name</th>
          <th>Created At</th>
          <th>Is Active</th>
        </thead>
        {registrations.map((registration, idx) => (
          <tr key={`${idx}-registrations-row-key`}>
            <td>{registration.id}</td>
            <td>{registration.teamName}</td>
            <td>{registration?.captainInfo.name}</td>
            <td>{registration?.captainInfo.uuid}</td>
            <td>{registration?.professorName}</td>
            <td>{registration?.createdAt}</td>
            <td>
              <StatusIcon value={registration?.active} />
            </td>
          </tr>
        ))}
      </Table>
    </PageWrapper>
  );
};

export default RegistrationsListPage;
