import { Button, FormField, Input, Modal } from '@bbnpm/bb-ui-framework';
import { useForm } from 'react-hook-form';

import { ButtonContainer, RequiredLabel } from '../../CommonComponents';

const AddMemberModal = ({
  isOpen,
  toggleIsOpen,
  registrationId,
  onSubmit,
  errors,
}) => {
  const { register, handleSubmit } = useForm();
  return (
    <Modal
      isOpen={isOpen}
      title="Add Team Member"
      portalContainerId="bfe-root"
      onClose={toggleIsOpen}>
      <form
        onSubmit={handleSubmit((values, e) => {
          e.preventDefault();
          onSubmit(values);
        })}>
        <FormField label={<RequiredLabel>First Name</RequiredLabel>}>
          <Input ref={register} name="firstName" required />
        </FormField>
        <FormField label={<RequiredLabel>Last Name</RequiredLabel>}>
          <Input ref={register} name="lastName" required />
        </FormField>
        <FormField
          label={<RequiredLabel>Email</RequiredLabel>}
          validationType={errors.email?.length && 'error'}
          validationContent={errors.email?.join(', ')}>
          <Input ref={register} name="email" required />
        </FormField>
        <FormField
          validationType={errors.base?.length && 'error'}
          validationContent={errors.base?.join(', ')}>
          <input
            type="hidden"
            ref={register}
            name="memberType"
            value={'member'}
          />
          <input
            type="hidden"
            ref={register}
            name="trading_challengeRegistrationId"
            value={registrationId}
          />
        </FormField>

        <ButtonContainer>
          <Button kind="primary" type="submit">
            Add
          </Button>
          <Button kind="secondary" onClick={toggleIsOpen} className="mx-1">
            Cancel
          </Button>
        </ButtonContainer>
      </form>
    </Modal>
  );
};

export default AddMemberModal;
