/* eslint react/no-unescaped-entities: 0 */
import { Typography } from '@bbnpm/bb-ui-framework';
import styled from 'styled-components';

import { PageWrapper } from '../CommonComponents';
import { TitleHero } from '../home_page/styles';

const TermsContainer = styled.div`
  max-width: 1140px;
  margin: 0 auto;
  strong {
    font-weight: 500;
  }
`;

const TermsAndConditions = () => {
  return (
    <PageWrapper width="100%">
      <TitleHero style={{ padding: '4rem 0', marginBottom: '2rem' }}>
        <Typography.LargeTitle style={{ maxWidth: '1140px', margin: '0 auto' }}>
          BLOOMBERG TRADING CHALLENGE TERMS & CONDITIONS
        </Typography.LargeTitle>
      </TitleHero>
      <TermsContainer>
        <Typography.Text>
          NO PURCHASE OR PAYMENT NECESSARY TO ENTER OR WIN. The Bloomberg
          Trading Challenge (the "
          <u>
            <strong>Challenge</strong>
          </u>
          ") is a skill-based event sponsored and run by Bloomberg L.P., a
          Delaware limited partnership with headquarters at 731 Lexington
          Avenue, New York, NY 10022 ("
          <u>
            <strong>Sponsor</strong>
          </u>
          " and, collectively with its affiliates, "
          <u>
            <strong>Bloomberg</strong>
          </u>
          "), operating agent for Bloomberg Finance L.P.
          <sup>1</sup>{' '}
        </Typography.Text>

        <Typography.Text>
          The Challenge is being offered to only those persons associated with
          universities and high schools that are Bloomberg Terminal® service ("
          <u>
            <strong>Terminal</strong>
          </u>
          ") subscribers (each, a "
          <u>
            <strong>School</strong>
          </u>
          "). The Challenge will be conducted on the Terminal pursuant to these
          official rules (the "
          <u>
            <strong>Terms & Conditions</strong>
          </u>
          "). The Challenge begins at 9:00 am ET on October 9, 2023 and ends at
          5:00 pm ET on November 17, 2023 (the "
          <u>
            <strong>Challenge Period</strong>
          </u>
          ").
        </Typography.Text>

        <Typography.Text>
          As discussed in more detail below, each team ("
          <u>
            <strong>Team</strong>
          </u>
          ") will consist of a unique three-five (3-5) member Team of students
          from the same School (each, a "
          <u>
            <strong>Team Member</strong>
          </u>
          "). Each Team must also have (i) a faculty advisor who is a current
          staff member at the Team's School and who is at least eighteen (1)
          years old (a "
          <u>
            <strong>Faculty Advisor</strong>
          </u>
          ") and (ii) a Team captain who is at least sixteen (16) years old and
          is a current student at the Team's School with his/her/their own
          Bloomberg Terminal account login ("
          <u>
            <strong>UUID</strong>
          </u>
          ") (a "
          <u>
            <strong>Captain</strong>
          </u>
          ", who is also a Team Member). The Team Members, Captains and Faculty
          Advisors shall collectively be referred to as "
          <u>
            <strong>Participants</strong>
          </u>
          ." Schools with more than three billable Terminals are eligible to
          have multiple Teams, and Schools with three billable Terminals or
          fewer are eligible to have one Team. As set forth below, there will be
          one set of prizes awarded to the Participants of the overall winning
          Team.
        </Typography.Text>

        <ol type="1">
          <li>
            <strong>Eligibility:</strong>
            <ol type="a">
              <li>
                Each Team Member must meet the following requirements:
                <ol type="i">
                  <li>
                    Throughout the Registration Period and the Challenge Period,
                    each Team Member must be:
                    <ol type="1">
                      <li>a full-time matriculated student at a School and</li>
                      <li>
                        reside (either permanently or temporarily in the case of
                        a student) in the country in which their School is
                        located.
                      </li>
                    </ol>
                  </li>
                  <li>
                    At the time of entry in the Challenge, each Team Member must
                    have reached sixteen (16) years of age.
                  </li>
                  <li>
                    For high school Teams ("
                    <u>
                      <strong>HS Teams</strong>
                    </u>
                    "), each Team Member must have been designated as such by
                    his/her/their Team's Faculty Advisor.
                  </li>
                  <li>
                    In the case of university Teams ("
                    <u>
                      <strong>University Teams</strong>
                    </u>
                    "), each Team Member must have been designated as such by
                    his/her/their Team's Captain.
                  </li>
                </ol>
              </li>

              <li>
                Each Captain must be meet the following requirements:
                <ol type="i">
                  <li>
                    Throughout the Registration Period and the Challenge Period,
                    each Captain must be:
                    <ol type="1">
                      <li>a full-time matriculated student at a School and</li>
                      <li>
                        reside (either permanently or temporarily in the case of
                        a student) in the country in which their School is
                        located.
                      </li>
                    </ol>
                  </li>
                  <li>
                    At the time of entry in the Challenge, each Captain must
                    have both:
                    <ol type="1">
                      <li>reached sixteen (16) years of age and</li>
                      <li>have his/her/their own UUID.</li>
                    </ol>
                  </li>
                  <li>
                    For HS Teams, each Captain must be designated as such by
                    his/her/their Team's Faculty Advisor during the Team
                    registration process.
                  </li>
                </ol>
              </li>

              <li>
                Each Faculty Advisor must meet the following requirements:
                <ol type="i">
                  <li>
                    Throughout the Registration Period and the Challenge Period,
                    each Faculty Advisor must:
                    <ol type="1">
                      <li>
                        be a faculty member in good standing at a School; and
                      </li>
                      <li>
                        {' '}
                        reside (either permanently or temporarily in the case of
                        a student) in the country in which their School is
                        located;
                      </li>
                    </ol>
                  </li>
                  <li>
                    At the time of entry in the Challenge, each Faculty Advisor
                    must have reached eighteen (18) years of age.
                  </li>
                  <li>
                    For University Teams, each Faculty Advisor must be
                    designated as such by his/her/their Team's Captain.
                  </li>
                </ol>
              </li>

              <li>
                No person whose School prohibits them from entering the
                Challenge or accepting the Prize is eligible. Also, employees or
                family or household members of any employee of Sponsor, its
                subsidiaries or affiliates, or any other person who directly or
                indirectly controls or is controlled by any of these entities,
                are not eligible to participate in the Challenge.
              </li>

              <li>
                Each Participant represents and warrants that he/she/they is/are
                fully eligible to participate in the Challenge in the role
                designated to him/her/them. Sponsor reserves the right to
                request proof Participants have met the eligibility requirements
                for their respective roles. Sponsor shall not be liable to
                ineligible Participants.
              </li>

              <li>
                For HS Teams, each Faculty Advisor will be responsible for
                securing any necessary consents or permissions from their
                students. Sponsor shall not be liable to any such students or
                for Participant's failure to secure any necessary consents or
                permissions.
              </li>

              <li>
                All decisions regarding eligibility and entry will be in
                Sponsor's sole and absolute discretion.
              </li>
            </ol>
          </li>
          <li>
            <strong>Entry:</strong>
            <ol type="a">
              <li>
                Each Participant must either register or be registered by a
                Captain or Faculty Advisor, as applicable, for the Challenge.
              </li>
              <li>
                Registration will take place on the HS Team registration page ("
                <u>
                  <strong>HS Challenge Site</strong>
                </u>
                ") and the University Team registration page ("
                <u>
                  <strong>University Challenge Site</strong>
                </u>
                "), respectively, between 9:00 am ET on August 28, 2023 and 5:00
                pm ET on September 29, 2023 (the "
                <u>
                  <strong>Registration Period</strong>
                </u>
                ").
              </li>
              <li>
                For HS Teams, a Faculty Advisor must have access to the
                Bloomberg for Education portal and will use the portal to
                register a unique Team of three-five Team Members from the same
                School, including a Captain. A Faculty Advisor may be the
                Faculty Advisor to more than one Team from his/her/their School.
              </li>
              <li>
                For University Teams, a Captain must have access to the
                Bloomberg for Education portal and will use the portal to
                register a unique Team of three-five Team Members, including
                themselves, from the same School, as well as a Faculty Advisor.
              </li>
              <li>
                Under no circumstance shall anyone under the age of sixteen (16)
                be permitted to register or be registered as a Participant of
                any kind. No Team Member may be a member of more than one Team.
              </li>
              <li>
                Whether a Faculty Advisor for an HS Team or a Captain for a
                University Team, he/she/they must: (i) follow the on-screen
                instructions and complete the entry form, which will require,
                among other things, the Team Member's name, name of their
                School, Team affiliation and email address (which must be from
                the same School email domain as the rest of the Team, and (ii)
                check the box to acknowledge they have read and agree to comply
                with both the Terms & Conditions and the Bloomberg for Education
                Privacy Policy. No generic entry forms will be accepted.
              </li>
              <li>
                For technical reasons and in the interest of fairness, no late
                entries will be accepted.
              </li>
              <li>
                By participating in the Challenge, each Faculty Advisor and
                University Team Captain warrants and represents that
                his/her/their submissions and contributions to the efforts of
                his/her/their Team will not infringe any third party's privacy,
                publicity or intellectual property rights, including but not
                limited to copyright, trademark, confidentiality, or trade
                secret rights, or the terms of any employment or other agreement
                to which they are bound.
              </li>
              <li>
                Following Sponsor's acceptance of an application to participate
                in the Challenge, Bloomberg will make available training videos
                about the core knowledge needed to participate in the Challenge.
              </li>
            </ol>
          </li>
          <li>
            <strong>Procedures:</strong> Each Team will have a U.S. one million
            dollar (US$1,000,000) notional amount to invest across securities.
            For each investment, the Captain will designate the dollar amount
            for your Team's trade from your Team's available funds. Each Team
            decides on its trade ideas and then the Captain submits the
            simulated trades on the Terminal starting on October 9, 2023, and
            thereafter during the Challenge Period. The following are parameters
            for selecting your securities:
            <ol type="a">
              <li>
                All simulated trades must be submitted through the Terminal.
              </li>
              <li>
                All official communications concerning the Challenge will be
                conducted on the Terminal using the MSG email functionality. The
                Sponsor may use alternate email addresses provided by
                Participants but Participants will be required to respond to
                official communications using the Terminal's MSG functionality.
              </li>
              <li>
                The starting notional amount to be invested by each Team is U.S.
                one million dollars (US$1,000,000). The entire starting notional
                amount of US$1,000,000 must be invested in full within the first
                week of the Challenge Period (i.e., no later than 9:00 am ET on
                October 16, 2023).
              </li>
              <li>
                The Challenge is limited to long-only trades; no short positions
                are allowed.
              </li>
              <li>
                All stocks that are members of the Bloomberg World Large, Mid
                and Small Cap Price Return Index (WLS {'<Index><GO>'}) are
                permitted for simulated trades. Exchange trade funds (ETFs) are
                not permitted.
              </li>
              <li>
                No single position held by a Team may be greater than twenty
                percent (20%) of the notional amount.
              </li>
              <li>No leverage is permitted.</li>
              <li>
                {' '}
                Information related to the Teams' respective standing in the
                Challenge will be made available on the Bloomberg for Education
                portal, an account for which is required to view this
                information.
              </li>
            </ol>
          </li>
          <li>
            <strong>Winner Selection:</strong>
            <ol type="a">
              <li>
                Sponsor's judges will be market specialists who are current
                employees of Sponsor.
              </li>
              <li>
                Sponsor's judges will select the winning Team (the "
                <u>
                  <strong>Winning Team</strong>
                </u>
                ") that submits trades that generate the highest Relative P&L
                over the Challenge Period. The definition of "Relative P&L" can
                be found on the Bloomberg Terminal under Calculations on the
                TMSG{'<GO>'} help menu (or a successor page).
              </li>
              <li>
                In the event of a tie between or among two or more Teams
                (returns measured to the thousandths decimal place), the
                Sponsor's judges will determine a single winner based upon the
                highest Absolute Return (as defined on the TMSG{'<GO>'} help
                menu) over the Challenge Period.
              </li>
              <li>
                All judging for the Winning Team will be in Sponsor's sole and
                absolute discretion, and Sponsor's decisions are final and
                binding on all matters relating to the Challenge.
              </li>
              <li>
                <strong>Prize:</strong> As set forth above, a prize will be
                awarded to each Participant on the Winning Team.
              </li>
              <li>
                Participants will be entitled to no prizes, including payment or
                reimbursement of any expenses, other than those listed herein.
              </li>
              <li>
                Each Participant on the Winning Team (each, a "
                <strong>Winner</strong>
                ") will receive the following (collectively, the{' '}
                <strong>"Prize"</strong>): (i) an 11-inch IPad Pro with 256GB
                <sup>2</sup>; (ii) a "victory basket" containing Bloomberg
                "swag"; and (iii) for the Team Members, including the Captain,
                an opportunity to network with the Bloomberg recruiting
                department to learn about potential internships/job
                opportunities at Bloomberg, which will not guarantee an offer of
                a position at Bloomberg (and an internship at Bloomberg will not
                guarantee any ongoing employment with Bloomberg). The overall
                winner of the Prize awarded to the Winners will be dependent
                upon the number of Participants on the Winning Team and is
                expected to be valued at approximately seven thousand five
                hundred dollars (US$7,500).
              </li>

              <li>
                In addition, as part of the Challenge, Sponsor will make a
                charitable contribution to a charity partner in a focus area of
                the Winning Team's choice. The value of the charitable
                contribution will be no less than five thousand dollars
                (US$5,000). Although Sponsor will consult with the Winning Team,
                final selection of the charity partner will be in Sponsor's sole
                discretion.
              </li>
              <li>
                Winners must meet all eligibility requirements and comply with
                all Terms & Conditions.
              </li>
              <li>
                Payment of all applicable taxes, insurance and any other fees
                and costs associated with the Prizes are the sole responsibility
                of the Winners.
              </li>
              <li>
                A Winner cannot assign or transfer any portion of the Prize to
                another person.
              </li>
              <li>
                No part of the Prize is redeemable for cash or exchangeable for
                any alternative. Sponsor reserves the right to revise, adjust,
                or substitute any part of the Prize without notice, in the event
                it is unavailable for any reason. Sponsor will make reasonable
                efforts to substitute a replacement for any unavailable element
                of the Prize such that the approximate value of the Prize as a
                whole, as determined by the Sponsor, remains as described in the
                Terms & Conditions, but no Participant shall be entitled to any
                monetary or other award or refund. Sponsor does not warrant the
                suitability of the Prize or any substituted Prize.
              </li>
              <li>
                Other restrictions may apply. Odds of winning depend on the
                number of eligible entries received.
              </li>
            </ol>
          </li>
          <li>
            <strong>Notification of Winner:</strong>
            <ol type="a">
              <li>
                All Winners will be notified by Sponsor via official email
                within five (5) business days of November 21, 2023. In the event
                a Team or a Participant does not meet one of the Terms &
                Conditions, Sponsor's judges may in their sole discretion select
                another Team as the Winner. Any portion of the Prize not used by
                the Winner will be forfeited.
              </li>
              <li>
                The names of the Winning Team, its Participants and their
                associated School will be posted on the Challenge Site.
              </li>
              <li>
                Winners will be required to respond (as directed) to the
                notification within seventy-two (72) hours of attempted
                notification. The failure timely to respond to the notification
                may result in disqualification of the Winning Team and, in such
                case, Sponsor may select an alternate Winning Team based on the
                judging criteria set forth in Section 5. Winners may be required
                to verify their eligibility and liability/publicity releases
                covering eligibility, liability, publicity and media appearance
                issues, unless prohibited by applicable law.
              </li>
              <li>
                The Prizes for each Winner will be sent to the Winner in care of
                the School of the Winning Team.
              </li>
            </ol>
          </li>
          <li>
            <strong>Liability Disclaimer:</strong> Sponsor is not responsible or
            liable for incorrect or inaccurate information, whether such error
            or inaccuracy is caused by a Participant, equipment or programming
            utilized in the Challenge, or technical or human error in processing
            submissions or processing trades, and Sponsor disclaims
            responsibility or liability for: (i) the accuracy, availability or
            timeliness of information published about the Challenge; (ii) any
            error, omission, interruption, deletion, defect, delay in operation
            or transmission, communications line failure, theft or destruction
            or access to, or alteration of entries; (iii) late, lost, delayed,
            damaged, misdirected, incomplete, illegible or unintelligible
            entries or trades; (iv) injuries, losses or damages of any kind
            caused by the Prize or resulting from acceptance, use or misuse of
            the Prize, or from participation in the Challenge; (v) printing or
            typographical errors in Challenge materials; or (vi) problems or
            technical malfunctions of telephone networks or lines, computer
            online systems, servers or providers, computer equipment, software
            or network congestion.
          </li>
          <li>
            <strong>Release:</strong> By participating in the Challenge, each
            Participant, on behalf of themselves and their heirs and assigns,
            releases and agrees to hold harmless, to the extent permitted by
            applicable law, Sponsor and its subsidiaries, affiliates, directors,
            officers, employees, representatives and agents from liability for
            claims, costs, injuries, losses or damages arising out of or in
            connection with the Challenge or acceptance, use or misuse of the
            Prize. However, nothing in the Terms & Conditions shall limit or
            exclude Sponsor's liability for death or personal injury as a result
            of its negligence, or for fraud, or for any other liability which
            may not be lawfully limited or excluded, or affect Participants'
            statutory rights, including in relation to any product received as
            part of the Prize.
          </li>
          <li>
            <strong>Additional Conditions:</strong> The Challenge is void where
            prohibited or restricted by law. All federal, state and local laws
            and regulations apply. By participating in the Challenge, each
            Participant agrees to be bound by the Terms & Conditions and
            Sponsor's judges' decisions, which are final and binding in all
            respects. All entries, trades and trading rationales, and all rights
            to use, reproduce, publish, modify and distribute such materials,
            become the exclusive property of Sponsor and will not be returned;
            however, Sponsor will grant Winners a limited non-exclusive license
            to publish their winning entries on their personal websites and in
            connection with applications for internships or employment as well
            as to link to any publication by Sponsor concerning the Challenge.
            Sponsor reserves the right, in its sole discretion, to refuse entry
            or disqualify any individual it finds to be: (i) tampering with the
            entry process, the operation of the Challenge, the Terminal or any
            Bloomberg website(s); (ii) acting in violation of the Terms &
            Conditions; or (iii) acting in an unsportsmanlike or disruptive
            manner, or with intent to annoy, abuse, threaten or harass any other
            person. ANY ATTEMPT BY A PARTICIPANT OR OTHER PERSON DELIBERATELY TO
            DAMAGE THE BLOOMBERG TERMINAL SERVICE OR BLOOMBERG'S WEBSITE(S) OR
            TO UNDERMINE THE OPERATION OF THE CHALLENGE OR COMMIT ANY ACT OF
            FRAUD MAY VIOLATE CRIMINAL AND CIVIL LAWS. SPONSOR RESERVES THE
            RIGHT TO DISQUALIFY ANY SUCH PERSON AND SEEK THE FULLEST REMEDIES
            PERMITTED BY LAW. By accepting the Prize, Winners grant Sponsor and
            its subsidiaries and affiliates the unconditional right to use their
            names, their schools' names and cities, and their photographs or
            other likenesses and/or statements about the Challenge for publicity
            and advertising purposes without further permission or financial
            compensation, except where prohibited by law.
          </li>
          <li>
            <strong>Privacy:</strong> Privacy matters are addressed in the
            Privacy Statement on the Bloomberg for Education portal, available
            at{' '}
            <Typography.Link
              href="https://www.bloomberg.com/notices/privacy-policy-education/"
              target="_blank">
              https://www.bloomberg.com/notices/privacy-policy-education/
            </Typography.Link>
            .
          </li>
          <li>
            <strong>General Terms:</strong> Sponsor shall enter into no
            correspondence or discussions initiated by or on behalf of a
            Participant or School concerning the application of standards in
            naming the Winning Team or any dispute regarding these Terms &
            Conditions, or the conduct or results of the Challenge. The
            Challenge may not be used in connection with any form of wagering or
            gambling. The Challenge shall be governed by, and construed in
            accordance with, the laws of the United States of America and the
            State of New York, without regard to the conflicts of laws rules
            thereof. All Participants and their heirs and assigns consent to the
            exclusive jurisdiction of the courts in the State and County of New
            York for all legal proceedings relating to this Challenge.
            Participants agree to use any materials or information procured as
            part of the Challenge only for Challenge purposes, as described
            herein, and not for any commercial or social media purposes.
          </li>
          <li>
            <strong>Cancellation; Postponement:</strong> If the Sponsor for any
            reason is unable to host the Challenge as planned, including
            infection by computer viruses, bugs, tampering, unauthorized
            intervention, fraud, technical failures, acts of God, war or
            terrorism, or any other causes that corrupt or affect the
            administration, security, fairness, integrity or proper conduct of
            the Challenge, Sponsor may cancel, terminate, modify, postpone or
            suspend the Challenge, in which event Sponsor will notify all
            Participants as soon as reasonably possible by MSG email.
          </li>
        </ol>

        <Typography.Text>Last Edited: August 14th, 2023.</Typography.Text>

        <hr />

        <Typography.Hint>
          <sup>1</sup> The BLOOMBERG TERMINAL service and data products are
          owned and distributed by Bloomberg Finance L.P. (BFLP) except (i) in
          Argentina, Australia and certain jurisdictions in the Pacific islands,
          Bermuda, China, India, Japan, Korea and New Zealand, where Bloomberg
          L.P. and its subsidiaries (BLP) distribute these products, and (ii) in
          Singapore and the jurisdictions serviced by Bloomberg's Singapore
          office, where a subsidiary of BFLP distributes these products. BLP
          provides BFLP and its subsidiaries with global marketing and
          operational support and service. No information or opinions herein
          constitutes a solicitation of the purchase or sale of securities or
          commodities.
        </Typography.Hint>
        <br />
        <Typography.Hint>
          <sup>2</sup> "iPad" is a trademark of Apple Corporation, which does
          not sponsor or authorize the Challenge or Sponsor.
        </Typography.Hint>
      </TermsContainer>
    </PageWrapper>
  );
};

export default TermsAndConditions;
